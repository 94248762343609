import React from 'react'
import Navbar from './Navbar'
import Feed from "./Feed"
const Home = () => {
  return (
    
    <div>
      <Navbar/>
      <div>
      <Feed/>
      </div>

    </div>
  
  )
}

export default Home
